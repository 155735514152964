$primary: #2b7dc6;
$success: #4db24d;
$info: #5bc0de;
$warning: #f0ad4e;
$danger: #d9534f;
$sidebar-nav-title-padding-y: 0.6rem;
$sidebar-nav-link-padding-y: 0.5rem;
$breadcrumb-margin-bottom: 20px;

@import '~@coreui/icons/scss/coreui-icons';
@import '~@coreui/coreui/scss/coreui';
