* {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
}

html {
  line-height: 1.15;
  text-size-adjust: 100%;
  font-size: 16px;
}

.controls {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;

  & > * {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
}
